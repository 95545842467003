import React, { useContext } from "react";
import { Drawer, Col, Row } from "antd";
import { AppContext } from "components/App";
import DrawerDropdown from "components/DrawerDropdown";
import { lolServers } from "helpers/common";
// import { lolLanguages } from "helpers/common";
import styles from "./Drawer.module.css";
import DrawerOption from "components/DrawerOption/DrawerOption";

const bodyStyle = {
  padding: "0px",
};

function AppDrawer({ isOpen, toggleDrawer }) {
  const { appState } = useContext(AppContext);
  const { server } = appState;

  return (
    <Drawer
      title="Options"
      placement="right"
      onClose={() => toggleDrawer()}
      visible={isOpen}
      closable={true}
      bodyStyle={bodyStyle}
      width={400}
    >
      <div className={styles.body_wrapper} style={{ padding: "20px" }}>
        <Row align="middle" justify="space-between">
          <Col span={4}>
            <h2 className={styles.dropdown_title}>{[server]}</h2>
          </Col>
          <Col span={8}>
            <DrawerDropdown
              obj={lolServers}
              handleMenuClick={(record) => {
                localStorage.setItem("random_legend_option_server", record.key);
                localStorage.removeItem("random_legend_summoners");
                window.location.reload(false);
              }}
              selectedKeys={[server]}
              buttonText="Servers"
            />
          </Col>
          {/* <Col span={8} offset={8}>
            <DrawerDropdown
              obj={lolLanguages}
              handleMenuClick={(record) => {
                appDispatch({
                  type: "setValue",
                  payload: record.key,
                  section: "language",
                });
                appDispatch({ type: "setValue", payload: record.key, section: "view" });
              }}
              selectedKeys={[appState.language]}
              buttonText="Languages"
            />
          </Col> */}
        </Row>
        {/* <DrawerOption title="All Fill"/> */}
        <DrawerOption title="Random Skins" />
      </div>
    </Drawer>
  );
}

export default AppDrawer;
