import React from "react";
import Background from "components/Background";
import { Button } from "antd";
import styles from "./Lanes.module.css";
import general from "assets/css/general.module.css";
import MobileBackground from "components/MobileBackground/MobileBackground";
import { useBreakpoints } from "react-breakpoints-hook";

function Lanes({ transition }) {
  let { sm } = useBreakpoints({
    sm: { min: 0, max: 767 },
  });

  return (
    <React.Fragment>
      {sm && <MobileBackground />}
      {!sm && <Background />}

      <div className={`${styles.confirm_wrapper} ${general.red_wrapper}`}>
        <Button
          type="primary"
          className={`${styles.confirm} animate__animated animate__pulse animate__slow animate__infinite`}
          size="large"
          onClick={() => transition()}
        >
          Randomize
        </Button>
      </div>
    </React.Fragment>
  );
}

export default Lanes;
