import React, { useEffect, useRef } from "react";
import styles from "./IconOverlay.module.css";
import general from "assets/css/general.module.css";
import { Input } from "antd";
import { UserOutlined } from "@ant-design/icons";

function IconOverlay({
  left,
  top,
  render,
  lockedin,
  lockin,
  value,
  handlesearch,
  loading,
  className,
  size = "medium",
}) {
  const buttonText = lockedin ? "Unlock" : "Lock In";
  const divDynamicStyle = `${styles.icon_overlay} animate__animated ${
    render === 3
      ? ""
      : render === 1
      ? "animate__fadeInRight"
      : "animate__fadeOutRight"
  }`;
  const searchRef = useRef(null);

  //Disable inputs when lockedin
  useEffect(() => {
    if (searchRef.current != null) {
      let searchInput = searchRef.current.input;
      let searchParent = searchInput.parentElement;
      setTimeout(() => searchInput.focus(), 10);
      if (lockedin) {
        searchInput.classList.add("ant-input-disabled");
        searchInput.setAttribute("disabled", "");
        setTimeout(
          () => searchParent.classList.add("ant-input-affix-wrapper-disabled"),
          10
        ); //Must delay parents disable to wait for child
      } else {
        searchInput.classList.remove("ant-input-disabled");
        searchInput.removeAttribute("disabled");
        setTimeout(
          () =>
            searchParent.classList.remove("ant-input-affix-wrapper-disabled"),
          10
        );
      }
    }
  }, [lockedin, render]);

  return (
    render !== 0 && (
      <div
        className={`${divDynamicStyle} ${className || ""}`}
        style={{
          left: left,
          top: top,
          pointerEvents: render === 2 ? "none" : "auto",
        }}
      >
        <Input.Search
          className={`${general.button_wrapper} ${
            lockedin === true ? general.blue_wrapper : general.red_wrapper
          }`}
          size={size}
          placeholder="Summoner Name"
          prefix={<UserOutlined />}
          enterButton={buttonText}
          onSearch={(value) => lockin(value)}
          spellCheck="false"
          ref={searchRef}
          onChange={(search) => handlesearch(search.target.value)}
          value={value}
          loading={loading}
        />
      </div>
    )
  );
}

export default React.memo(IconOverlay);
