import React, { useContext } from "react";
import styles from "./ChampionBanner.module.css";
import { SummonerContext } from "components/Summoner";

const ChampionBanner = ({ className }) => {
  const { summoner } = useContext(SummonerContext);
  const icon = require(`assets/images/icons/${summoner.lane}.svg`);

  return (
    <div className={`${styles.banner_wrapper} ${className || ""}`}>
      <img src={icon} alt={summoner.lane} />
      {summoner.name && <span>{summoner.name}</span>}
    </div>
  );
};

export default React.memo(ChampionBanner);
