import React, { useEffect, useRef, useContext, useReducer } from "react";
import { Col, Carousel } from "antd";
import { ColorExtractor } from "react-color-extractor";
import styles from "./Summoner.module.css";
import ChampionInfo from "components/ChampionInfo";
import ChampionBanner from "components/ChampionBanner";
import { Scrollbars } from "react-custom-scrollbars";
import { AppContext } from "components/App";
import { dataDragon } from "helpers/common";
import axios from "axios";
import Shuffle from "assets/images/misc/random.svg";

const initialState = {
  colors: ["black"],
  moreStats: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "setValue":
      return {
        ...state,
        [action.section]: action.payload,
      };
    default:
      return state;
  }
};

export const SummonerContext = React.createContext();
function Summoner({ delay, count, summoner, setLoading, renderHorizontal }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { appState, appDispatch } = useContext(AppContext);
  const splashart = summoner.champion.image.url;
  const parentRef = useRef(null);
  const { colors, moreStats } = state;

  //Set additional stat information for championinfo
  useEffect(() => {
    if (moreStats === null || moreStats.id !== summoner.champion.id) {
      const path = dataDragon(
        `cdn/${appState.version}/data/${appState.language}/champion/${summoner.champion.id}.json`
      );

      axios
        .get(path)
        .then((res) => {
          setLoading(false);
          dispatch({
            type: "setValue",
            section: "moreStats",
            payload: res.data.data[summoner.champion.id],
          });
        })
        .catch((err) => {
          appDispatch({
            type: "setApiError",
            payload: { section: "More Stats", error: err },
          });
        });
    }
  }, [
    moreStats,
    appState.version,
    appState.language,
    summoner.champion.id,
    setLoading,
  ]);

  return (
    <React.Fragment>
      {moreStats && (
        <SummonerContext.Provider
          value={{
            summonerState: state,
            summonerDispatch: dispatch,
            summoner: summoner,
          }}
        >
          <Col
            ref={parentRef}
            style={{ width: `${100 / count - 2}%` }}
            className={`${
              styles[renderHorizontal.layout ? "landscape" : "portrait"]
            } ${styles.col} ${
              colors.length > 1
                ? `animate__animated ${
                    renderHorizontal.animation
                      ? "animate__fadeInLeftBig"
                      : "animate__fadeInDownBig"
                  } animate__delay-${delay}s`
                : ""
            }`}
          >
            <div className={styles.summoner_header}>
              <div
                className={styles.shuffle}
                onClick={() =>
                  appDispatch({ type: "shuffleSummoner", lane: summoner.lane })
                }
              >
                <img src={Shuffle} alt="Shuffle"></img>
              </div>
              <div className={styles.splashart_wrapper}>
                <img
                  src={splashart}
                  alt={splashart}
                  className={styles.splashart}
                />
                <div className={styles.splashart_name}>
                  {summoner.champion.name}
                </div>
              </div>
              {renderHorizontal.animation && (
                <div className={styles.champion_info}>
                  <Carousel draggable={true}>
                    <ChampionInfo.Description showExpand />
                    <ChampionInfo.Stats />
                    <ChampionInfo.Abilities version={appState.version} />
                  </Carousel>
                </div>
              )}
            </div>
            <ChampionBanner className={styles.champion_banner} />
            {!renderHorizontal.animation && (
              <Scrollbars className={styles.summoner_body}>
                <ChampionInfo.Description />
                <ChampionInfo.Stats />
                <ChampionInfo.Abilities version={appState.version} />
              </Scrollbars>
            )}
            <ColorExtractor
              src={splashart}
              getColors={(colors) =>
                dispatch({
                  type: "setValue",
                  section: "colors",
                  payload: colors,
                })
              }
            />
          </Col>
        </SummonerContext.Provider>
      )}
    </React.Fragment>
  );
}

export default React.memo(Summoner);
