import React, { useContext, useState } from "react";
import { Switch, Typography } from "antd";
import { AppContext } from "components/App";
import styles from "./DrawerOption.module.css";
const { Title } = Typography;

function DrawerOption({ title }) {
  const { appState, appDispatch } = useContext(AppContext);
  const lowercaseTitle = title.replace(/\s/g, "").toLowerCase();
  const { [lowercaseTitle]: defaultValue } = appState;
  const [checked, setChecked] = useState(defaultValue);

  //Function toggles local checked state and adds loading option for other components to consume
  const toggleChecked = () => {
    appDispatch({
      type: "setValue",
      section: lowercaseTitle,
      payload: !checked,
    });
    setChecked(!checked);
    localStorage.setItem(`random_legend_option_${lowercaseTitle}`, !checked);
  };

  return (
    <div className={styles.drawerOption}>
      <Title level={3}>{title}</Title>
      <Switch
        className={checked && styles.drawerChecked}
        checked={checked}
        onClick={toggleChecked}
      />
    </div>
  );
}

export default DrawerOption;
