import React from "react";
import styles from "./SummonersLoading.module.css";

function SummonersLoading({ loading }) {
  return (
    <div
      className={`${styles.spinner_wrapper} animate__animated ${
        loading ? "animate__fadeIn" : "animate__fadeOut"
      }`}
    >
      <div className={styles.spinner_border}>
        <div className={styles.spinner_core}></div>
      </div>
    </div>
  );
}

export default React.memo(SummonersLoading);
