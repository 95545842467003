import React from "react";
import { ReactComponent as ErrorIcon } from "assets/images/icons/ErrorIcon.svg";
import styles from "./ErrorBoundary.module.css";

const defaultError = "Something went wrong.";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorText: defaultError };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorText: defaultError };
  }

  componentDidCatch(error) {
    try {
      const parseError = error.toString().split("Error: ")[1];
      parseError && this.setState({ errorText: parseError });
    } catch (err) {
      console.error(error);
      console.error(err);
    }
  }

  render() {
    const { hasError, errorText } = this.state;
    if (hasError) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.icon_wrapper}>
            <ErrorIcon />
          </div>
          <div className={styles.h_wrapper}>
            <h1 className={styles.h1}>{errorText}</h1>
            <h2 className={styles.h2}>
              Please contact{" "}
              <a href="mailto:the.lotus.perspective@gmail.com">support</a> if
              the problem persists.
            </h2>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
