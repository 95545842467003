import React, { useContext, useLayoutEffect, useState } from "react";
import { AppContext } from "components/App";
import { logEvent } from "firebase/analytics";
import { Row } from "antd";
import Summoner from "components/Summoner";
import styles from "./Summoners.module.css";
import SummonersLoading from "components/SummonersLoading";
import SpringScrollbar from "components/SpringScrollbar/SpringScrollbar";

function Summoners() {
  const { appState, appAnalytics } = useContext(AppContext);
  const { summoners } = appState;
  const [loading, setLoading] = useState(true);
  const shouldRenderHorizontalAnimation = (innerWidth) =>
    innerWidth / (summoners.length + 1) < 300;
  const shouldRenderHorizontalLayout = (innerWidth) =>
    shouldRenderHorizontalAnimation(innerWidth) && innerWidth >= 768;
  const [renderHorizontal, setRenderHorizontal] = useState({
    animation: shouldRenderHorizontalAnimation(window.innerWidth),
    layout: shouldRenderHorizontalLayout(window.innerWidth),
  });

  const summonerArray = summoners.map((summoner, index) => (
    <Summoner
      key={summoner.lane}
      delay={index}
      count={appState.summoners.length}
      summoner={summoner}
      setLoading={setLoading}
      renderHorizontal={renderHorizontal}
    />
  ));

  //Add resize event for animation and layout to horizontal/vertical
  useLayoutEffect(() => {
    function handleResize() {
      setRenderHorizontal({
        animation: shouldRenderHorizontalAnimation(window.innerWidth),
        layout: shouldRenderHorizontalLayout(window.innerWidth),
      });
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Track user made it to this screen
  useLayoutEffect(() => {
    logEvent(appAnalytics, "screen_view", {
      firebase_screen: "Summoner Page",
    });
  }, []);

  return (
    <Row className={styles.row} count={summoners.length}>
      <SummonersLoading loading={loading} />
      {renderHorizontal.animation && (
        <SpringScrollbar>{summonerArray}</SpringScrollbar>
      )}
      {!renderHorizontal.animation && summonerArray}
    </Row>
  );
}

export default Summoners;
