import React, { useState, useCallback, useContext } from "react";
import styles from "./Header.module.css";
import Drawer from "components/Drawer";
import { SettingOutlined } from "@ant-design/icons";
import { ReactComponent as LaneLogo } from "assets/images/misc/LaneLogo.svg";
import { AppContext } from "components/App/App";

function Header() {
  const { appDispatch } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={styles.header}>
      <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
      <div
        onClick={() =>
          appDispatch({
            type: "setValue",
            payload: "lanes",
            section: "view",
          })
        }
        className={styles.logo_wrapper}
      >
        <LaneLogo />
      </div>
      <SettingOutlined className={styles.menu} onClick={() => toggleDrawer()} />
    </div>
  );
}

export default Header;
