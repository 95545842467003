import { collection, getDocs } from "firebase/firestore";

export const dataDragon = (path) => {
  return `https://ddragon.leagueoflegends.com/${path}`;
};

export const capitalizeFirstLetterOnly = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const importIcon = (lane, error) => {
  return error
    ? require(`assets/images/icons/ErrorIcon.svg`).default
    : require(`assets/images/icons/${lane}Icon.svg`);
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
};

export const lolInit = { server: "NA1", code: "en_US" };
export const lolServers = {
  id: "server",
  text: "server",
  arr: [
    { server: "BR1" },
    { server: "EUN1" },
    { server: "EUW1" },
    { server: "JP1" },
    { server: "KR" },
    { server: "LA1" },
    { server: "LA2" },
    { server: "NA1" },
    { server: "OC1" },
    { server: "TR1" },
    { server: "RU" },
  ],
};

export const lolLanguages = {
  id: "code",
  text: "language",
  arr: [
    { code: "es_CZ", language: "Czech" },
    { code: "el_GR", language: "Greek" },
    { code: "pl_PL", language: "Polish" },
    { code: "ro_RO", language: "Romanian" },
    { code: "hu_HU", language: "Hungarian" },
    { code: "en_GB", language: "English (United Kingdom)" },
    { code: "de_DE", language: "German" },
    { code: "es_ES", language: "Spanish (Spain)" },
    { code: "it_IT", language: "Italian" },
    { code: "fr_FR", language: "French" },
    { code: "ja_JP", language: "Japanese" },
    { code: "ko_KR", language: "Korean" },
    { code: "es_MX", language: "Spanish (Mexico)" },
    { code: "es_AR", language: "Spanish (Argentina)" },
    { code: "pt_BR", language: "Portuguese" },
    { code: "en_US", language: "English (United States)" },
    { code: "en_AU", language: "English (Australia)" },
    { code: "ru_RU", language: "Russian" },
    { code: "tr_TR", language: "Turkish" },
    { code: "ms_MY", language: "Malay" },
    { code: "en_PH", language: "English (Republic of the Philippines)" },
    { code: "en_SG", language: "English (Singapore)" },
    { code: "th_TH", language: "Thai" },
    { code: "vn_VN", language: "Vietnamese" },
    { code: "id_ID", language: "Indonesian" },
    { code: "zh_MY", language: "Chinese (Malaysia)" },
    { code: "zh_CN", language: "Chinese (China)" },
    { code: "zh_TW", language: "Chinese (Taiwan)" },
  ],
};

export const getDataKey = async (db) => {
  const keyCollectionRef = collection(db, "league_token");
  return await getDocs(keyCollectionRef);
};

export const wrapPathwKey = (server = "", path, params = "", key) => {
  return `https://${server}.api.riotgames.com/${path}${params}?api_key=${key}`;
};
