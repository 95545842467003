import React, { useEffect, useRef, useReducer } from "react";
import styles from "./Icon.module.css";
import general from "assets/css/general.module.css";
import { importIcon } from "helpers/common";
import IconOverlay from "components/IconOverlay";
import { Tooltip } from "antd";
import useLockIn from "helpers/use-lock-in";

const initialState = {
  left: "0%",
  top: "0%",
  moving: false,
};
const reducer = (state, action) => {
  switch (
    action.type //renderOverlay: 0 = never rendered, 1 = fadein, 2 = fadeout, 3 = mobile
  ) {
    case "position": //* Reposition summoner name box
      return {
        ...state,
        left: action.payload.left,
        top: action.payload.top,
        moving: false,
      };
    case "setValue": //* Set IconOverlay's input value
      return {
        ...state,
        value: action.payload,
      };
    default:
      return state;
  }
};

function Icon({ render, lane }) {
  const iconRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { left, top } = state;
  const {
    error,
    summonerIcon,
    iconloading,
    lockedin,
    value,
    handleSearch,
    toggle,
    lockIn,
    renderOverlay,
  } = useLockIn(lane);
  const icon = importIcon(lane, error);

  //* Function executes once on false (when the background hasn't rendered yet) and once on true - calculates where the overlay should go
  const calcPosition = () => {
    let computedStyle = window.getComputedStyle(iconRef.current);
    let parentWidth = iconRef.current.parentElement.offsetWidth;
    let parentHeight = iconRef.current.parentElement.offsetHeight;
    let iconLeft = computedStyle.left;
    let iconTop = computedStyle.top;
    const isDuoLane = lane === "Support" || lane === "Bot";
    const leftOffset = isDuoLane ? 34 : 0;
    const topOffset = isDuoLane ? 6 : 0;

    let leftPercentage = `calc(${(
      (Number(iconLeft.substring(0, iconLeft.length - 2)) / parentWidth) * 100 +
      5
    ).toFixed(2)}% - ${leftOffset}vh)`;
    let topPercentage = `calc(${(
      (Number(iconTop.substring(0, iconTop.length - 2)) / parentHeight) * 100 -
      5
    ).toFixed(2)}% + ${topOffset}vh)`;
    return { left: leftPercentage, top: topPercentage };
  };
  useEffect(() => {
    if (iconRef.current !== null)
      dispatch({ type: "position", payload: calcPosition() });
  }, [render]);

  const dynamicStyle = `${styles.icon} ${
    renderOverlay === 1 || lockedin ? styles.highlight_wrapper : ""
  } animate__animated animate__fadeInDown`;
  return (
    render && (
      <React.Fragment>
        <Tooltip title={error} placement="bottom" color="#FF2345" key="#FF2345">
          <div
            className={dynamicStyle}
            section={lane}
            ref={iconRef}
            onClick={toggle}
            style={
              summonerIcon ? { backgroundImage: `url(${summonerIcon})` } : {}
            }
          >
            <img src={icon} alt={lane} />
            {lockedin && <div className={general.locked_in}></div>}
          </div>
        </Tooltip>
        <IconOverlay
          loading={iconloading}
          left={left}
          top={top}
          render={renderOverlay}
          lockedin={lockedin}
          lockin={lockIn}
          value={value}
          handlesearch={handleSearch}
        />
      </React.Fragment>
    )
  );
}

export default React.memo(Icon);
