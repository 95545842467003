import React, { useReducer, useEffect, useCallback } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import Lanes from "components/Lanes";
import Header from "components/Header";
import Summoners from "components/Summoners";
import axios from "axios";
import {
  dataDragon,
  getDataKey,
  lolInit,
  mapOrder,
  wrapPathwKey,
} from "helpers/common";
import ChampionLanes from "assets/misc/ChampionLanes.json";
import "antd/dist/antd.css";
import "./App.css";
import "animate.css/animate.min.css";

const firebaseConfig = {
  apiKey: "AIzaSyALuxuIIlqHB1ZuEnJYwFhvyOb5iLBtgAc",
  authDomain: "random-legend.firebaseapp.com",
  projectId: "random-legend",
  storageBucket: "random-legend.appspot.com",
  messagingSenderId: "952409049633",
  appId: "1:952409049633:web:00fd2e5ed25fbbd4703160",
  measurementId: "G-3ZVXQRSCMD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

//------------- Reducer ------------
let initialState = {
  apiLoading: false,
  apiKey: null,
  apiError: null,
  server: lolInit.server,
  laneAnimation: "animate__fadeIn animate__delay-1s",
  summoners: [],
  champions: {},
  language: lolInit.code,
  version: "",
  view: "lanes",
  randomskins: true,
};

//* Loop through session storage keys with random_legend_option and set initial state
const RANDOM_LEGEND_OPTION = "random_legend_option_";
const storageMapping = Object.keys(localStorage)
  .filter((key) => key.startsWith(RANDOM_LEGEND_OPTION))
  .reduce((res, key) => {
    const splitKey = key.split(RANDOM_LEGEND_OPTION)[1];
    const value = localStorage[key];
    res[splitKey] = value === "false" ? false : value === "true" ? true : value;
    return res;
  }, {});
initialState = { ...initialState, ...storageMapping };

const cloneSummoners = (summoners) => JSON.parse(JSON.stringify(summoners));
const getSummonerIndex = (lane, summoners) =>
  summoners.findIndex((summoner) => summoner.lane === lane);
const reducer = (state, action) => {
  let summoners, summonerIndex;

  switch (action.type) {
    case "confirm":
      return {
        ...state,
        laneAnimation: "animate__zoomOut",
        apiLoading: true,
      };
    case "removeSummoner":
      summoners = cloneSummoners(state.summoners);
      summonerIndex = getSummonerIndex(action.lane, summoners);
      summoners.splice(summonerIndex, 1);

      localStorage.setItem(
        "random_legend_summoners",
        JSON.stringify(summoners)
      );
      return {
        ...state,
        summoners: [...summoners],
      };
    case "setSummoner":
      action.payload.lane = action.lane;
      summoners = cloneSummoners(state.summoners);
      summonerIndex = getSummonerIndex(action.lane, summoners);
      if (summonerIndex === -1) {
        summoners.push(action.payload);
      } else {
        summoners[summonerIndex] = action.payload;
      }

      localStorage.setItem(
        "random_legend_summoners",
        JSON.stringify(summoners)
      );
      return {
        ...state,
        summoners: [...summoners],
      };
    case "shuffleSummoner":
      summoners = cloneSummoners(state.summoners);
      summonerIndex = getSummonerIndex(action.lane, summoners);
      summoners[summonerIndex].shuffle = true;
      return {
        ...state,
        summoners: [...summoners],
      };
    case "setAllSummoners":
      return {
        ...state,
        summoners: action.payload,
        view: "summoners",
        laneAnimation: "",
        apiLoading: false,
      };
    case "setApiError":
      const { error, section } = action.payload;
      const errText = `Error in section ${section} - ${
        error?.response?.statusText || error?.message || error?.toString()
      }`;
      logEvent(analytics, "screen_view", {
        firebase_screen: "Error Page",
      });
      return {
        ...state,
        apiError: errText,
      };
    case "setValue":
      return {
        ...state,
        [action.section]: action.payload,
        optionLoading: true,
      };
    default:
      return state;
  }
};

const filterChampions = (obj, summoner, summoners) => {
  const newObj = {};
  const { lane } = summoner;

  for (let key in obj) {
    const getSummChampions = summoners.filter(
      (summoner) => summoner.champion && obj[key].id === summoner.champion.id
    );
    if (obj[key].lanes.includes(lane) && getSummChampions.length === 0) {
      //Filter by lane, then filter out other summoners champions
      newObj[key] = obj[key];
    }
  }

  return newObj;
};

//TODO: Throttle network and see how that goes
const setSummonerAttr = async (summoner, summonerChampions, randomskins) => {
  let keys = Object.keys(summonerChampions);

  //Randomly select champion for each summoner
  let getChampion = summonerChampions[keys[(keys.length * Math.random()) << 0]];
  const { id, version } = getChampion;

  const setChampionDefaultURL = () => {
    getChampion.image.url = dataDragon(`cdn/img/champion/splash/${id}_0.jpg`);
  };

  if (randomskins) {
    const championDataPath = dataDragon(
      `cdn/${version}/data/en_US/champion/${id}.json`
    );

    await axios
      .get(championDataPath)
      .then((res) => {
        const skins = res.data.data[id].skins;
        const randomSkin = skins[Math.floor(Math.random() * skins.length)];
        getChampion.image.url = dataDragon(
          `cdn/img/champion/splash/${id}_${randomSkin.num}.jpg`
        );
      })
      .catch((err) => {
        console.error(err);
        setChampionDefaultURL();
      });
  } else {
    setChampionDefaultURL();
  }
  summoner.champion = getChampion;
  summoner.shuffle = false;

  return summoner;
};

//---------- Component -----------------
export const AppContext = React.createContext();
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    apiError,
    apiKey,
    version,
    champions,
    apiLoading,
    laneAnimation,
    summoners,
    language,
    server,
    randomskins,
  } = state;
  const dynamicStyle = `App animate__animated ${laneAnimation}`;

  //Get api key
  useEffect(() => {
    getDataKey(db)
      .then((res) => {
        const keyRef = res.docs[0];
        const { key } = keyRef.data();
        dispatch({
          type: "setValue",
          payload: key,
          section: "apiKey",
        });
      })
      .catch((err) => {
        dispatch({
          type: "setApiError",
          payload: { section: "Key", error: err },
        });
      });
  }, []);

  //Get current version
  useEffect(() => {
    if (apiKey) {
      const path = dataDragon("api/versions.json");
      axios
        .get(path)
        .then((res) => {
          dispatch({
            type: "setValue",
            payload: res.data[0],
            section: "version",
          });
        })
        .catch((err) => {
          dispatch({
            type: "setApiError",
            payload: { section: "Version", error: err },
          });
        });
    }
  }, [apiKey]);

  //Get all the champions once, and pair with json
  useEffect(() => {
    if (version !== "") {
      const path = dataDragon(`cdn/${version}/data/${language}/champion.json`);
      axios
        .get(path)
        .then((res) => {
          const resChampions = res.data.data;
          const keys = Object.keys(resChampions);

          for (let key of keys) {
            if (
              !ChampionLanes.data.hasOwnProperty(key) ||
              (ChampionLanes.data.hasOwnProperty(key) &&
                !ChampionLanes.data[key].hasOwnProperty("lanes"))
            ) {
              resChampions[key].lanes = [
                "Top",
                "Bot",
                "Jungle",
                "Mid",
                "Support",
              ];
            } else {
              resChampions[key].lanes = ChampionLanes.data[key].lanes;
            }
          }

          dispatch({
            type: "setValue",
            payload: resChampions,
            section: "champions",
          });

          if (localStorage.random_legend_summoners) {
            dispatch({
              type: "setValue",
              payload: JSON.parse(localStorage.random_legend_summoners),
              section: "summoners",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: "setApiError",
            payload: { section: "Champions", error: err },
          });
        });
    }
  }, [version, language]);

  //Reduce champions by lane -> get everything needed for summoner from api
  useEffect(() => {
    if (apiLoading) {
      const sortOrder = ["Top", "Jungle", "Mid", "Bot", "Support"];

      //Add default randomize function if nothing has been selected
      if (summoners.length === 0) {
        const defaultSummoners = sortOrder.map((lane) => ({
          lane,
          shuffle: false,
          summonerMasteries: [],
        }));

        summoners.push(...defaultSummoners);
      }

      //if all the champions respective summoners have been locked in, then switch to summoner view
      if (
        summoners.filter((summoner) => summoner.summonerMasteries === undefined)
          .length === 0
      ) {
        for (let summoner of summoners) {
          const summonerChampions = filterChampions(
            champions,
            summoner,
            summoners
          );

          (async () => {
            summoner = await setSummonerAttr(
              summoner,
              summonerChampions,
              randomskins
            );
          })();
        }

        //Sort summoners into the right lane order when they are revealed to the user
        const sortedSummoners = mapOrder(summoners, sortOrder, "lane");
        setTimeout(() => {
          //Delay for zoomout animation
          dispatch({ type: "setAllSummoners", payload: sortedSummoners });
        }, 1000);
      }
    }

    //Get Champion mastery for summoners
    for (let summoner of summoners) {
      if (
        summoner.id !== undefined &&
        summoner.summonerMasteries === undefined
      ) {
        const path = wrapPathwKey(
          server,
          `lol/champion-mastery/v4/champion-masteries/by-summoner/${summoner.id}`,
          "",
          apiKey
        );
        axios
          .get(path)
          .then((res) => {
            summoner.summonerMasteries = res.data;
            dispatch({
              type: "setSummoner",
              payload: summoner,
              lane: summoner.lane,
            });
          })
          .catch((err) => {
            dispatch({
              type: "setApiError",
              payload: { section: `Champions (${summoner.name})`, error: err },
            });
          });
      } else if (summoner.summonerMasteries === undefined) {
        summoner.summonerMasteries = [];
        dispatch({
          type: "setSummoner",
          payload: summoner,
          lane: summoner.lane,
        });
      }
    }
  }, [apiLoading, apiKey, summoners, champions, server, randomskins]);

  //Handle shuffle from champion view
  useEffect(() => {
    const summonerShuffle = summoners.filter(
      (summoner) => summoner.shuffle === true
    );
    if (summonerShuffle.length > 0) {
      for (let summoner of summonerShuffle) {
        const summonerChampions = filterChampions(
          champions,
          summoner,
          summoners
        );

        (async () => {
          summoner = await setSummonerAttr(
            summoner,
            summonerChampions,
            randomskins
          );
          dispatch({
            type: "setSummoner",
            payload: summoner,
            lane: summoner.lane,
          });
        })();
      }
    }
  }, [champions, summoners, randomskins]);

  //Confirm ready for champion view
  const confirm = useCallback(() => {
    dispatch({ type: "confirm" });
  }, []);

  useEffect(() => {
    if (apiError) {
      throw new Error(apiError);
    }
  }, [apiError]);

  return (
    <AppContext.Provider
      value={{
        appState: state,
        appDispatch: dispatch,
        appAnalytics: analytics,
      }}
    >
      <Header />
      <div className={dynamicStyle}>
        {state.view === "lanes" && <Lanes transition={confirm} />}
        {state.view === "summoners" && <Summoners />}
      </div>
    </AppContext.Provider>
  );
}

export default App;
