import MobileRow from "components/MobileRow/MobileRow";
import React from "react";
import styles from "./MobileBackground.module.css";

function MobileBackground(props) {
  return (
    <div className={styles.background}>
      <MobileRow lane="Top" />
      <MobileRow lane="Mid" />
      <MobileRow lane="Jungle" />
      <MobileRow lane="Bot" />
      <MobileRow lane="Support" />
    </div>
  );
}

export default MobileBackground;
