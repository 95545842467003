import React, { useEffect, useRef, useReducer } from "react";
import summonersRift from "assets/images/misc/SR_Map.jpg";
import styles from "./Background.module.css";
import Icon from "components/Icon";

const initialState = { width: "100%", height: "100%", render: false };
const reducer = (state, action) => {
  switch (action.type) {
    case "position":
      return {
        ...state,
        render: true,
        width: action.width,
        height: action.height,
      };
    default:
      return state;
  }
};

function Background() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const imageRef = useRef(null);
  const { height, width, render } = state;

  //Runs once and adds an event listener to resize the background shadow as the background resizes
  useEffect(() => {
    let index = 0;
    let imageRender = setInterval(() => {
      //Have to set an interval to wait for summoners rift to render
      if (imageRef.current?.height > 0) {
        resizeOverlay();
        clearInterval(imageRender);
      } else if (index > 100) {
        clearInterval(imageRender);
      } else {
        index++;
      }
    }, 100);

    window.addEventListener("resize", resizeOverlay);
    return () => {
      window.removeEventListener("resize", resizeOverlay);
    };
  }, []);

  //Function to resize the background shadow
  const resizeOverlay = () =>
    dispatch({
      type: "position",
      width: imageRef.current.width + 20,
      height: imageRef.current.height,
    });

  return (
    <div className={styles.background_wrapper}>
      <img
        alt="Summoner's Rift"
        className={styles.background}
        src={summonersRift}
        ref={imageRef}
      />
      <div className={styles.shadow} style={{ height: height, width: width }}>
        <Icon render={render} lane="Top" />
        <Icon render={render} lane="Mid" />
        <Icon render={render} lane="Jungle" />
        <Icon render={render} lane="Bot" />
        <Icon render={render} lane="Support" />
      </div>
    </div>
  );
}

export default React.memo(Background);
