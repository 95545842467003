import React, { useContext, useCallback, useState } from "react";
import styles from "./ChampionInfo.module.css";
import { SummonerContext } from "components/Summoner";
import { Typography, Slider, Tooltip, Avatar } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { dataDragon } from "helpers/common";
const { Title, Paragraph, Text } = Typography;

const toTitleCase = (phrase) => {
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const Abilities = ({ version }) => {
  const COLORINDEX = 0;
  const { summonerState } = useContext(SummonerContext);
  const { moreStats, colors } = summonerState;
  const spells = [moreStats.passive].concat(moreStats.spells);
  const [curSpell, setCurSpell] = useState(0);
  const [spellShadow, setSpellShadow] = useState([
    colors[COLORINDEX],
    "transparent",
    "transparent",
    "transparent",
    "transparent",
  ]);

  //Set the currently clicked spell
  const findSpell = useCallback(
    (e) => {
      const index = e.children[e.children.length - 1].getAttribute("index");
      const cloneSpellShadow = JSON.parse(JSON.stringify(spellShadow));
      for (let spellIndex in cloneSpellShadow) {
        cloneSpellShadow[spellIndex] =
          index === spellIndex ? colors[COLORINDEX] : "transparent";
      }

      setSpellShadow(cloneSpellShadow);
      setCurSpell(index);
    },
    [spellShadow, colors]
  );

  const formatSpell = spells[curSpell].description.replace(
    /<\/?[^>]+(>|$)/g,
    ""
  );

  return (
    <div className={`${styles.wrapper} ${styles.abilities} champion-info`}>
      <Title level={2}>Champion Abilities</Title>
      <div className={styles.abilities_wrapper}>
        <div
          className={`${styles.abilities_icon_wrapper} champion-info__abilities-icon-wrapper`}
        >
          {spells.map((spell, index) => {
            const src = dataDragon(
              "id" in spell
                ? `cdn/${version}/img/spell/${spell.image.full}`
                : `cdn/${version}/img/passive/${spell.image.full}`
            );
            return (
              <Tooltip
                key={spell.name}
                title={spell.name}
                placement="right"
                onClick={(e) => findSpell(e.currentTarget)}
              >
                <Avatar
                  src={src}
                  style={{ boxShadow: `0px 0px 3px 3px ${spellShadow[index]}` }}
                />
                <i hidden index={index}></i>
              </Tooltip>
            );
          })}
        </div>
        <div className={styles.abilities_text_wrapper}>
          <Scrollbars className={styles.paragraph}>{formatSpell}</Scrollbars>
        </div>
      </div>
    </div>
  );
};

const Description = ({ showExpand = false }) => {
  const { summonerState } = useContext(SummonerContext);
  const { moreStats } = summonerState;

  return (
    <div className={`${styles.wrapper} ${styles.description} champion-info`}>
      <Title level={2}>{toTitleCase(moreStats.title)}</Title>
      <div>
        <Scrollbars>
          <Paragraph
            className={styles.paragraph}
            ellipsis={{ rows: showExpand ? 2 : 5, expandable: true }}
          >
            {moreStats.lore}
          </Paragraph>
        </Scrollbars>
      </div>
    </div>
  );
};

const statNames = [
  "attack damage",
  "attack range",
  "attack speed",
  "hp regen",
  "move speed",
];
const Stats = () => {
  const { summonerState } = useContext(SummonerContext);
  const [slider, setSlider] = useState(1);
  const { moreStats } = summonerState;
  const stats = moreStats.stats;

  const calcStats = useCallback(
    (stats, key) => {
      let scaler = stats[`${key}perlevel`];
      scaler = scaler === undefined ? 0 : scaler;
      let value = (stats[key] + scaler * (slider - 1)).toFixed(2);
      return value.replace(".00", "");
    },
    [slider]
  );

  return (
    <div className={`${styles.wrapper} ${styles.stats} champion-info`}>
      <Title level={2}>Champion Stats</Title>
      <div className={styles.stats_wrapper}>
        <div className={`${styles.stats_info} champion-info__stats-info`}>
          {Object.keys(stats)
            .sort()
            .map((key, index) => {
              try {
                const image = require(`assets/images/stats/${key}.svg`);
                let tooltip =
                  key.indexOf("mp") > -1
                    ? `${moreStats.partype} ${
                        key.indexOf("regen") > -1 ? "Regen" : ""
                      }`
                    : key === "spellblock"
                    ? "Magic Resist"
                    : key;
                const statName = statNames.filter(
                  (stat) => stat.replace(/\s/g, "") === tooltip
                );
                tooltip =
                  statName.length > 0 ? toTitleCase(statName[0]) : tooltip;

                const value = calcStats(stats, key);
                return (
                  <Tooltip key={index} title={tooltip.toUpperCase()}>
                    <img src={image} alt={tooltip} />
                    <Text>{value}</Text>
                  </Tooltip>
                );
              } catch (e) {
                return null;
              }
            })}
        </div>
        <Slider
          tooltipPlacement="left"
          value={slider}
          min={1}
          max={18}
          vertical
          reverse
          onChange={(value) => setSlider(value)}
        />
      </div>
    </div>
  );
};

export default class ChampionInfo extends React.Component {
  static Abilities = Abilities;
  static Description = Description;
  static Stats = Stats;
}
