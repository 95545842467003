import React, { useLayoutEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";

const SpringScrollbars = ({ children }) => {
  const springRef = useRef(null);

  useLayoutEffect(() => {
    if (springRef.current) {
      setTimeout(() => {
        const scrollHeight = springRef.current.getScrollHeight();
        let index = 2;
        const keepScrolling = setInterval(() => {
          if (index >= children.length) {
            clearInterval(keepScrolling);
          } else {
            springRef.current.view.scroll({
              top: scrollHeight / (children.length - index),
              left: 0,
              behavior: "smooth",
            });
          }
          index++;
        }, 1000);
      }, 2000);
    }
  }, []);

  return <Scrollbars ref={springRef}>{children}</Scrollbars>;
};

export default SpringScrollbars;
