import { useCallback, useContext, useEffect, useReducer } from "react";
import { wrapPathwKey, dataDragon } from "./common";
import { AppContext } from "components/App/App";
import axios from "axios";

const initialState = {
  renderOverlay: 0,
  lockedin: false,
  value: "",
  error: "",
  iconloading: false,
  summonerIcon: "",
};

const reducer = (state, action) => {
  switch (
    action.type //renderOverlay: 0 = never rendered, 1 = fadein, 2 = fadeout, 3 = mobile
  ) {
    case "GET_SUMMONER_ERROR":
      return {
        ...state,
        lockedin: false,
        error: action.payload,
        iconloading: false,
      };
    case "GET_SUMMONER_REQUEST":
      return {
        ...state,
        lockedin: false,
        iconloading: true,
        summonerIcon: "",
      };
    case "GET_SUMMONER_SUCCESS":
      return {
        ...state,
        lockedin: true,
        error: "",
        iconloading: false,
        renderOverlay: 2,
        summonerIcon: action.payload.profileIconId
          ? dataDragon(
              `cdn/${action.version}/img/profileicon/${action.payload.profileIconId}.png`
            )
          : "",
      };
    case "setValue": //* Set IconOverlay's input value
      return {
        ...state,
        value: action.payload,
      };
    case "toggleLock": //* Toggle icon's lock state
      return {
        ...state,
        lockedin: !state.lockedin,
        error: "",
        renderOverlay: action.payload,
        summonerIcon: "",
      };
    case "toggleOverlay": //* Toggle IconOverlay's render
      return {
        ...state,
        renderOverlay:
          state.renderOverlay === 0 ? 1 : state.renderOverlay === 1 ? 2 : 1,
        error: "",
        value: state.lockedin ? state.value : "",
      };
    case "toggleFill": //* Toggle fill animation on icon from fill
      return {
        ...state,
        renderOverlay: 0,
        fillClass: state.fillClass === "" ? "icon_fill" : "",
        moving: true,
      };
    case "setDefaultValues":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

function useLockIn(lane) {
  const { appState, appDispatch } = useContext(AppContext);
  const { apiKey, summoners, version, server } = appState;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { lockedin } = state;

  useEffect(() => {
    if (summoners) {
      const loadSummoner = summoners.find(
        (summoner) => summoner?.lane === lane
      );
      const { name, profileIconId } = loadSummoner || {};

      loadSummoner &&
        dispatch({
          type: "setDefaultValues",
          payload: {
            lockedin: true,
            value: name,
            summonerIcon: profileIconId
              ? dataDragon(
                  `cdn/${version}/img/profileicon/${profileIconId}.png`
                )
              : "",
          },
        });
    }
  }, [summoners]);

  const lockIn = useCallback(
    (value) => {
      if (value.trim() !== "" && !lockedin) {
        dispatch({ type: "GET_SUMMONER_REQUEST" });
        const path = wrapPathwKey(
          server,
          "lol/summoner/v4/summoners/by-name/",
          encodeURI(value),
          apiKey
        );
        axios
          .get(path)
          .then((res) => {
            appDispatch({ type: "setSummoner", payload: res.data, lane: lane });
            dispatch({
              type: "GET_SUMMONER_SUCCESS",
              payload: res.data,
              version: version,
            });
          })
          .catch((err) => {
            console.error(err);
            appDispatch({ type: "removeSummoner", lane: lane });
            dispatch({
              type: "GET_SUMMONER_ERROR",
              payload: err.response
                ? err.response.statusText
                : "Missing Response. Try checking the selected server in options.",
            });
          });
      } else {
        if (!lockedin) {
          appDispatch({ type: "setSummoner", payload: {}, lane: lane });
        } else {
          appDispatch({ type: "removeSummoner", payload: {}, lane: lane });
        }
        dispatch({
          type: "toggleLock",
          payload: lockedin ? 1 : value.trim() === "" ? 2 : 1,
        });
      }
    },
    [appDispatch, version, server, lockedin, lane]
  );

  const handleSearch = useCallback(
    (value) => dispatch({ type: "setValue", payload: value }),
    []
  );

  //* Function renders the iconoverlay sibling component when the icon is clicked
  const toggle = () => dispatch({ type: "toggleOverlay" });

  return { ...state, lockIn, handleSearch, toggle };
}

export default useLockIn;
