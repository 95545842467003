import IconOverlay from "components/IconOverlay/IconOverlay";
import { importIcon } from "helpers/common";
import useLockIn from "helpers/use-lock-in";
import React from "react";
import styles from "./MobileRow.module.css";
import general from "assets/css/general.module.css";

function MobileRow({ lane }) {
  const {
    summonerIcon,
    iconloading,
    lockedin,
    value,
    handleSearch,
    lockIn,
    error,
  } = useLockIn(lane);
  const icon = importIcon(lane, error);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.icon}
        section={lane}
        style={summonerIcon ? { backgroundImage: `url(${summonerIcon})` } : {}}
      >
        <img src={icon} alt={lane} />
        {lockedin && <div className={general.locked_in}></div>}
      </div>
      <IconOverlay
        loading={iconloading}
        handlesearch={handleSearch}
        lockedin={lockedin}
        lockin={lockIn}
        value={value}
        render={3}
        className={styles.icon_overlay}
        size="large"
      />
      {error && <h3 className={styles.error}>{error}</h3>}
    </div>
  );
}

export default MobileRow;
