import React from "react";
import { Dropdown, Button, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

function DrawerDropdown({ obj, handleMenuClick, selectedKeys, buttonText }) {
  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={selectedKeys}>
      {obj.arr.map((row) => (
        <Menu.Item key={row[obj.id]}>{row[obj.text]}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="primary" block>
        {buttonText} <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
}

export default React.memo(DrawerDropdown);
